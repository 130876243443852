import memoize from 'lodash/memoize';

import get from 'lodash/get';

import { BodyLinks } from 'types';
import sanitizeLink from './sanitizeLink';

export default memoize((data: unknown): BodyLinks | null => {
  const id = get(data, '_key');

  if (!id) {
    return null;
  }

  return {
    id,
    type: get(data, '_type', ''),
    title: get(data, 'title', ''),
    style: get(data, 'style', ''),
    links: get(data, 'links', []).map((link: unknown) => {
      return sanitizeLink(link);
    }),
  };
});
