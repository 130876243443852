import memoize from 'lodash/memoize';

import get from 'lodash/get';

import { HeaderLinkWithList } from 'types';
import sanitizeHeaderLinkWithListItem from 'sanitizers/sanitizeHeaderLinkWithListItem';

export default memoize((data: unknown): HeaderLinkWithList | null => {
  const id = get(data, '_key');

  if (!id) {
    return null;
  }

  return {
    id,
    type: get(data, '_type', 'headerLinkWithList'),
    headerText: get(data, 'headerText', ''),
    headerUrl: get(data, 'headerUrl', ''),
    listItems: get(data, 'listItems', []).map((listItem: unknown) => {
      return sanitizeHeaderLinkWithListItem(listItem);
    }),
  };
});
