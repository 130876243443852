import React from 'react';
import BlockContent from '@sanity/block-content-to-react';

import Language from 'constants/Language';

import { ArticleEventBlock as IArticleEventBlock } from 'types';
import generateDateRange from 'utils/generateDateRange';

interface Props {
  block: IArticleEventBlock | null;
}

const ArticleEventBlock: React.FC<Props> = ({ block }) => {
  if (!block) {
    return null;
  }

  return (
    <div className="ArticleEventBlock apercu col-12 text-sm">
      {block.header && (
        <div className="ArticleEventBlock__header border-green color-green">
          {block.header}
        </div>
      )}
      <div className="ArticleEventBlock__details flex flex-col">
        <div className='flex flex-row color-black pb_75 md:pb1_5'>
          <div className="col-4 pr1 md:pr0 md:col-3">{Language.t('Global.location')}</div>
          <div className="col-7 md:col-9">{block.location}</div>
        </div>
        <div className='flex flex-row color-black pb_75 md:pb1_5'>
          <div className="col-4 pr1 md:pr0 md:col-3">{Language.t('Global.date')}</div>
          <div className="col-7 md:col-9">
            {generateDateRange(block.startDate, block.endDate)}
          </div>
        </div>
      </div>
      {block.description && (
        <div className="ArticleEventBlock__description">
          <BlockContent blocks={block.description} />
        </div>
      )}
    </div>
  );
};

export default ArticleEventBlock;
