import React, { Component } from 'react';
import cx from 'classnames';

import Language from 'constants/Language';
import sanityImgUtil from 'utils/sanityImgUtil';
import { Img, Button } from 'components/base';
import { Image } from 'types';

interface Props {
  image: Image;
  toggleModal: (index: number) => void;
  className?: string;
  currentImageIndex: number;
}

class ImageModal extends Component<Props> {
  render() {
    const { image, className, toggleModal, currentImageIndex } = this.props;

    return (
      <div
        className={cx(
          'ImageModal relative z-overlay flex items-center justify-center vh100 t0 r0 l0 fixed',
          className
        )}
      >
        <Button
          className="bg-color-transparent color-bronze text-md apercu flex justify-end absolute t0 r0 p_4"
          ariaLabel={Language.t('ImageModal.closeButtonAriaLabel')}
          label={Language.t('ImageModal.close')}
          onClick={() => {
            toggleModal(currentImageIndex);
          }}
        />
        <div className="ImageModal__image-container flex items-center justify-center">
          <Img className="w100 h100" src={sanityImgUtil(image, 700)} alt={image.alt} />
        </div>
        {image.caption && (
          <div className="ImageModal__caption text-left color-white text-sm apercu absolute b0 l0 p_4 py1">
            {image.caption}
          </div>
        )}
      </div>
    );
  }
}

export default ImageModal;
