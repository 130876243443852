import memoize from 'lodash/memoize';

import get from 'lodash/get';

import { ColumnList } from 'types';
import sanitizeListItem from './sanitizeListItem';

export default memoize((data: unknown): ColumnList | null => {
  const id = get(data, '_key');
  
  if (!id) {
    return null;
  }

  return {
    id,
    title: get(data, 'title', ''),
    items: get(data, 'items', []).map((listItem: unknown) => {
      return sanitizeListItem(listItem);
    }),
  };
});
