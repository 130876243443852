import { ArticleLink } from 'types';

export default (articleLinks: ArticleLink[]): ArticleLink[] => {
  let articlesCopy: ArticleLink[] = articleLinks;

  while (articlesCopy.length % 3 !== 0) {
    articlesCopy.push({
      id: '',
      title: '',
      briefTitle: '',
      heroImage: {
        id: '',
        src: '',
        alt: '',
        caption: '',
        crop: null,
        hotspot: null,
        metadata: null,
      },
      thumbnailImage: {
        id: '',
        src: '',
        alt: '',
        caption: '',
        crop: null,
        hotspot: null,
        metadata: null,
      },
      model: {
        id: '',
        src: '',
      },
      authorsInfo: {
        authors: [
          {
            firstName: '',
            lastName: '',
          },
        ],
      },
      date: '',
      slug: '',
      topics: [
        {
          id: '',
          title: '',
          slug: '',
        },
      ],
    });
  }

  return articlesCopy;
};
