import memoize from 'lodash/memoize';

import get from 'lodash/get';

import { TwoColumnTextBlock } from 'types';
import sanitizeLink from './sanitizeLink';
import sanitizeColumn from './sanitizeColumn';

export default memoize((data: unknown): TwoColumnTextBlock | null => {
  const id = get(data, '_key');

  if (!id) {
    return null;
  }

  return {
    id,
    type: get(data, '_type', 'twoColumnTextBlock'),
    title: get(data, 'title', ''),
    columns: get(data, 'columns', []).map((column: unknown) => {
      return sanitizeColumn(column);
    }),
    links: get(data, 'links', []).map((link: unknown) => {
      return sanitizeLink(link);
    }),
  };
});
