import React from 'react';
import { Video } from 'components/base';
import { ArticleVideoBlock as IArticleVideoBlock } from 'types';

interface Props {
  block: IArticleVideoBlock | null;
}

const ArticleVideoBlock: React.FC<Props> = ({ block }) => {

  if (!block) {
    return null;
  };

  return <Video className="py2_5 md:py5" variant="article" videoId={block.videoId} />;
};

export default ArticleVideoBlock;
