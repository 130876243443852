import React from 'react';
import hashify from 'object-hash';

import {
  ArticleScheduleBlock as IArticleScheduleBlock,
  ArticleScheduleBlockItem,
} from 'types';

interface Props {
  block: IArticleScheduleBlock | null;
}

const ArticleScheduleBlock: React.FC<Props> = ({ block }) => {
  if (!block) {
    return null;
  }

  return (
    <div className="ArticleScheduleBlock apercu col-12 text-sm pb3">
      {block.items.map((item: ArticleScheduleBlockItem) => (
        <div key={hashify(item)} className="flex flex-row color-black">
          <div className="col-4 pr1 md:pr0 md:col-3">{item.time}</div>
          <div className="col-7 md:col-9">{item.event}</div>
        </div>
      ))}
    </div>
  );
};

export default ArticleScheduleBlock;
