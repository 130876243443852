import React, { Component } from 'react';
import cx from 'classnames';
import sanityImgUtil from 'utils/sanityImgUtil';

import Language from 'constants/Language';

import { Img, Button } from 'components/base';
import ImageModal from 'components/ImageModal';
import { ArticleStudentWorkBlock as IArticleStudentWorkBlock, Image } from 'types';

interface Props {
  block: IArticleStudentWorkBlock | null;
  className?: string;
}

interface State {
  activeCaptionIndex: number;
  activeModalIndex: number;
}

class ArticleStudentWorkBlock extends Component<Props, State> {
  state = {
    activeCaptionIndex: -1,
    activeModalIndex: -1,
  };

  toggleModal = (index: number) => {
    if (this.state.activeModalIndex === index) {
      this.setState({ activeModalIndex: -1 });
      document.body.classList.remove('disable-body-scroll');
    } else {
      this.setState({ activeModalIndex: index });
      document.body.classList.add('disable-body-scroll');
    }
  };

  toggleCaption = (index: number) => {
    if (this.state.activeCaptionIndex === index || this.state.activeModalIndex === index) {
      this.setState({ activeCaptionIndex: -1 });
    } else {
      this.setState({ activeCaptionIndex: index });
    }
  };

  render() {
    const { block, className } = this.props;
    const { activeCaptionIndex, activeModalIndex } = this.state;

    if (!block || !block.images.length) return null;

    return (
      <div
        className={cx(
          'ArticleStudentWorkBlock grid-black-12-columns border-right-black flex flex-col',
          className
        )}
      >
        <div className="ArticleStudentWorkBlock__images-container flex flex-col overflow-hidden">
          {block.images.map((image: Image, index: number) => (
            <div key={image.id} className="ArticleStudentWorkBlock__block p2 lg:p4 relative">
              <div className="flex">
                <div>
                  {activeModalIndex === index && (
                    <ImageModal
                      image={image}
                      toggleModal={this.toggleModal}
                      currentImageIndex={activeModalIndex}
                    />
                  )}
                  <Button
                    className="w100 h100 bg-color-transparent"
                    containerClassName="w100 h100 bg-color-transparent"
                    ariaLabel={Language.t('SocialMedia.genericSocialMediaButtonAriaLabel')}
                    onClick={() => {
                      this.toggleModal(index);
                    }}
                    onMouseEnter={() => {
                      this.toggleCaption(index);
                    }}
                    onMouseLeave={() => {
                      this.toggleCaption(index);
                    }}
                  >
                    <div className="ArticleStudentWorkBlock__image-container relative">
                      <Img
                        className={cx(`ArticleStudentWorkBlock__image-${index} image-drop-shadow`)}
                        src={sanityImgUtil(image, 1200)}
                        alt={image.alt}
                      />
                    </div>
                    {image.caption && (
                      <div
                        className={cx(
                          'ArticleStudentWorkBlock__caption flex text-left py1 p_4 apercu bg-color-white text-sm color-black transition-short image-drop-shadow col-12',
                          {
                            'ArticleStudentWorkBlock__caption--is-active opacity-1':
                              index === activeCaptionIndex && index !== activeModalIndex,
                            'opacity-0 events-none':
                              index !== activeCaptionIndex || index === activeModalIndex,
                          }
                        )}
                      >
                        {image.caption}
                      </div>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default ArticleStudentWorkBlock;
