import React from 'react';

import cx from 'classnames';
import generateAuthorList from 'utils/generateAuthorList';
import { AuthorsInfo, BackgroundColorType, TopicLink } from 'types';

import { Button } from 'components/base';
import generateTopicUrl from 'utils/generateTopicUrl';

interface Props {
  className?: string;
  authorsInfo: AuthorsInfo;
  bgColor: BackgroundColorType;
  topic: TopicLink;
}

const AuthorList: React.FC<Props> = ({ className, authorsInfo, bgColor, topic }) => {
  const hasWhiteBackground = bgColor === BackgroundColorType.WHITE;
  const hasBlackBackground = bgColor === BackgroundColorType.BLACK;
  const hasBronzeBackground = bgColor === BackgroundColorType.BRONZE;

  return (
    <>
      <span className={cx('AuthorList inline-flex flex-col pt_625 md:pt1_375', className)}>
        <span
          className={cx('flex flex-row', {
            'color-green': hasWhiteBackground,
            'color-black': hasBronzeBackground,
            'color-white': hasBlackBackground,
          })}
        >
          {authorsInfo && authorsInfo.authors && (
            <span className="text-meta-sm apercu line-height-sm">
              {generateAuthorList(authorsInfo.authors)}
            </span>
          )}
        </span>
        <span className="text-meta-sm apercu flex flex-col">
          {authorsInfo.details && (
            <span
              className={cx('mr_25', {
                'color-green': hasWhiteBackground,
                'color-black': hasBronzeBackground,
                'color-white': hasBlackBackground,
              })}
            >
              {authorsInfo.details}
            </span>
          )}
          {topic && (
            <span>
              <Button
                to={generateTopicUrl(topic.slug)}
                className={cx(
                  'AuthorList__button inline-flex text-decoration-none transition-shortest',
                  {
                    'color-bronze': hasWhiteBackground || hasBlackBackground,
                    'AuthorList--style-bronze__button color-white': hasBronzeBackground,
                    'AuthorList--style-white__button': hasWhiteBackground,
                    'AuthorList--style-black__button': hasBlackBackground,
                  }
                )}
                ariaLabel={topic.title}
              >
                ({topic.title})
              </Button>
            </span>
          )}
        </span>
      </span>
    </>
  );
};

export default AuthorList;
