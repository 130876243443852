import memoize from 'lodash/memoize';

import get from 'lodash/get';

import { ArticleVideoBlock } from 'types';

export default memoize((data: unknown): ArticleVideoBlock | null => {
  const id = get(data, '_key');

  if (!id) {
    return null;
  }

  return {
    id,
    type: get(data, '_type', 'articleVideoBlock'),
    videoId: get(data, 'videoId', ''),
  };
});
