//TO-DO: Update placeholder images
import hashify from 'object-hash';

//Default Image. Alt is used as a fallback when no alt description has been added.
export const DefaultImage = {
  id: hashify('assets/images/pratt-default-image-1.png'),
  src: 'assets/images/pratt-default-image-1.png',
  alt: 'Pratt School of Architecture Default Image',
  caption: '',
  crop: null,
  hotspot: null,
  metadata: null,
};

//Global default images
const defaultImageOne = {
  id: hashify('assets/images/pratt-default-image-1.png'),
  src: '/assets/images/pratt-default-image-1.png',
  alt: 'Bronze ring shape with 3D effect',
  caption: '',
  crop: null,
  hotspot: null,
  metadata: null,
};

const defaultImageTwo = {
  id: hashify('assets/images/pratt-default-image-2.png'),
  src: '/assets/images/pratt-default-image-2.png',
  alt: 'Shiny metal ball with 3D effect',
  caption: '',
  crop: null,
  hotspot: null,
  metadata: null,
};

const defaultImageThree = {
  id: hashify('assets/images/pratt-default-image-3.png'),
  src: '/assets/images/pratt-default-image-3.png',
  alt: 'Yellow shape with 3D effect',
  caption: '',
  crop: null,
  hotspot: null,
  metadata: null,
};

const defaultImageFour = {
  id: hashify('assets/images/pratt-default-image-4.png'),
  src: '/assets/images/pratt-default-image-4.png',
  alt: 'Gray cube shape with 3D effect',
  caption: '',
  crop: null,
  hotspot: null,
  metadata: null,
};

export const defaultImages = [
  defaultImageOne,
  defaultImageTwo,
  defaultImageThree,
  defaultImageFour,
];
