import formatISOStringToDate from 'utils/formatISOStringToDate';

export default (startDate: string, endDate: string): string => {
  const formattedStartDateWithYearMonthTime = formatISOStringToDate(startDate, 'MMMM d yyyy');

  const formattedStartDateWithTime = formatISOStringToDate(
    startDate,
    'h:mm a'
  );

  const formattedEndDateWithTime = formatISOStringToDate(endDate, 'h:mm a');

  return `${formattedStartDateWithYearMonthTime}, at ${formattedStartDateWithTime}-${formattedEndDateWithTime}`
};
