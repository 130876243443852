import memoize from 'lodash/memoize';

import get from 'lodash/get';

import { ArticleScheduleBlock } from 'types';
import sanitizeArticleScheduleBlockItem from './sanitizeArticleScheduleBlockItem';

export default memoize((data: unknown): ArticleScheduleBlock | null => {
  const id = get(data, '_key');

  if (!id) {
    return null;
  }

  return {
    id,
    type: get(data, '_type', 'articleScheduleBlock'),
    items: get(data, 'items', []).map((item: unknown) => {
      return sanitizeArticleScheduleBlockItem(item);
    }),
  };
});
