import React from 'react';

import get from 'lodash/get';

import { LinkListVariantType } from 'types';
import sanitizeArticleRelatedLinks from 'sanitizers/sanitizeArticleRelatedLinks';
import sanitizeArticleImageBlock from 'sanitizers/sanitizeArticleImageBlock';
import sanitizeArticleImageFullWidthBlock from 'sanitizers/sanitizeArticleImageFullWidthBlock';
import sanitizeArticleVideoBlock from 'sanitizers/sanitizeArticleVideoBlock';
import sanitizeTwoColumnTextBlock from 'sanitizers/sanitizeTwoColumnTextBlock';
import sanitizeArticleEventBlock from 'sanitizers/sanitizeArticleEventBlock';
import sanitizeArticleScheduleBlock from 'sanitizers/sanitizeArticleScheduleBlock';
import sanitizeArticleImageCarouselBlock from 'sanitizers/sanitizeArticleImageCarouselBlock';
import sanitizeBodyLinks from 'sanitizers/sanitizeBodyLinks';
import sanitizeHeaderLinkWithList from 'sanitizers/sanitizeHeaderLinkWithList';

import { LinkList, HeaderLinkWithList as HeaderLinkWithListComponent } from 'components/base';
import ArticleImageBlockComponent from 'components/ArticleImageBlock';
import ArticleImageFullWidthBlockComponent from 'components/ArticleImageFullWidthBlock';
import ArticleVideoBlockComponent from 'components/ArticleVideoBlock';
import TwoColumnTextBlockComponent from 'components/TwoColumnTextBlock';
import ArticleEventBlockComponent from 'components/ArticleEventBlock';
import ArticleScheduleBlockComponent from 'components/ArticleScheduleBlock';
import ArticleImageCarouselBlockComponent from 'components/ImageCarousel';

export const Green = (props: unknown) => {
  const children = get(props, 'children', null);

  if (!children) {
    return null;
  }

  return <span className="color-green">{children}</span>;
};

export const ArticleRelatedLinks = (props: unknown) => {
  return (
    <LinkList
      variant={LinkListVariantType.ARTICLE_SIDEBAR}
      data={sanitizeArticleRelatedLinks(get(props, 'node'))}
      className="py2 lg:none"
    />
  );
};

export const BodyLinks = (props: unknown) => {
  return (
    <LinkList variant={LinkListVariantType.BODY} data={sanitizeBodyLinks(get(props, 'node'))} />
  );
};

export const HeaderLinkWithList = (props: unknown) => {
  return <HeaderLinkWithListComponent data={sanitizeHeaderLinkWithList(get(props, 'node'))} />;
};

export const ArticleImageBlock = (props: unknown) => {
  return <ArticleImageBlockComponent content={sanitizeArticleImageBlock(get(props, 'node'))} />;
};

export const ArticleImageFullWidthBlock = (props: unknown) => {
  return (
    <ArticleImageFullWidthBlockComponent
      content={sanitizeArticleImageFullWidthBlock(get(props, 'node'))}
    />
  );
};

export const ArticleVideoBlock = (props: unknown) => {
  return <ArticleVideoBlockComponent block={sanitizeArticleVideoBlock(get(props, 'node'))} />;
};

export const ArticleImageCarouselBlock = (props: unknown) => {
  return (
    <div className="ArticleImageCarousel relative">
      <ArticleImageCarouselBlockComponent
        block={sanitizeArticleImageCarouselBlock(get(props, 'node'))}
      />
    </div>
  );
};

export const TwoColumnTextBlock = (props: unknown) => {
  return <TwoColumnTextBlockComponent block={sanitizeTwoColumnTextBlock(get(props, 'node'))} />;
};

export const ArticleEventBlock = (props: unknown) => {
  return <ArticleEventBlockComponent block={sanitizeArticleEventBlock(get(props, 'node'))} />;
};

export const ArticleScheduleBlock = (props: unknown) => {
  return <ArticleScheduleBlockComponent block={sanitizeArticleScheduleBlock(get(props, 'node'))} />;
};
