import React from 'react';

import { Img } from 'components/base';
import { ArticleImageFullWidthBlock as IArticleImageFullWidthBlock } from 'types';

interface Props {
  content: IArticleImageFullWidthBlock | null;
}

const ArticleImageFullWidthBlock: React.FC<Props> = ({ content }) => {
  if (!content) {
    return null;
  }

  return (
    <div className="ArticleImageFullWidthBlock flex flex-col py2_5 xxl:py5">
      <Img className="w100" src={content.src} alt={content.alt} />
      {content.caption && (
        <div className="text-caption apercu color-black pt_5">{content.caption}</div>
      )}
    </div>
  );
};

export default ArticleImageFullWidthBlock;
