import React from 'react';
import { File3D } from 'types';
import Model3D from 'components/Model3D';

interface Props {
  pageNotFoundModel: File3D;
  className?: string;
}

const PageNotFound: React.FC<Props> = ({ pageNotFoundModel }) => {

  return (
    <div className="PageNotFound grid-black-12-columns border-right-black border-bottom-black">
      <div className="PageNotFound__image-container flex items-center justify-center mxauto p1 relative">
        {pageNotFoundModel && (<Model3D src={pageNotFoundModel.src} />)}
      </div>
    </div>
  );
};

export default PageNotFound;
