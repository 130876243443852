import memoize from 'lodash/memoize';

import get from 'lodash/get';

import { HeaderLinkWithListItem } from 'types';

export default memoize((data: unknown): HeaderLinkWithListItem => {
  
  return {
    text: get(data, 'text', ''),
  };
});
