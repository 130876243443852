import React, { Component, createRef, RefObject } from 'react';
import get from 'lodash/get';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import debounce from 'lodash/debounce';
import { Mouse } from 'types';
import withBreakpoints, {
  Breakpoints,
  InjectedProps as WithBreakpointsProps,
} from 'lib/withBreakpoints';

interface PassedProps {
  src: string;
}

type Props = PassedProps & WithBreakpointsProps;

class Model3D extends Component<Props> {
  modelRef: RefObject<HTMLDivElement> = createRef();
  modelContainerRef: RefObject<HTMLDivElement> = createRef();
  scene!: THREE.Scene;
  camera!: THREE.PerspectiveCamera;
  renderer!: THREE.WebGLRenderer;
  controls!: OrbitControls;
  requestID!: number;
  object!: THREE.Object3D;
  mouse!: Mouse;
  windowHalf = new THREE.Vector2(window.innerWidth / 2, window.innerHeight / 2);

  componentDidMount() {
    this.sceneSetup();
    this.addCustomSceneObjects();
    this.startAnimation();
    window.addEventListener('resize', this.debounceHandleResize);
    document.addEventListener('mousemove', this.onMouseMove, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debounceHandleResize);
    document.removeEventListener('mousemove', this.onMouseMove, false);
    window.cancelAnimationFrame(this.requestID);
  }

  handleResize = () => {
    this.windowHalf.set(window.innerWidth / 2, window.innerHeight / 2);

    const modelContainer = this.modelContainerRef && this.modelContainerRef.current;

    if (modelContainer) {
      const modelContainerWidth = modelContainer.clientWidth;
      const modelContainerHeight = modelContainer.clientHeight;

      this.renderer.setSize(modelContainerWidth, modelContainerHeight);
      this.camera.aspect = modelContainerWidth / modelContainerHeight;

      this.camera.updateProjectionMatrix();
    }
  };

  sceneSetup = () => {
    const breakpointIsSmDown = [
      Breakpoints.SMALL.label,
      Breakpoints.SMALL_EXTRA_SMALL.label,
      Breakpoints.EXTRA_SMALL.label,
    ].includes(this.props.currentBreakpoint);

    //Adjust camera position depending on screen size.
    const cameraPositionZOnSmallDevices = -16;
    const cameraPositionZOnLargerDevices = -10;

    const modelContainer = this.modelContainerRef && this.modelContainerRef.current;
    const model = this.modelRef && this.modelRef.current;

    if (modelContainer && model) {
      const modelContainerWidth = modelContainer.clientWidth;
      const modelContainerHeight = modelContainer.clientHeight;

      this.scene = new THREE.Scene();

      //Camera
      this.camera = new THREE.PerspectiveCamera(
        3,
        modelContainerWidth / modelContainerHeight,
        1,
        1000
      );

      this.camera.position.z = breakpointIsSmDown
        ? cameraPositionZOnSmallDevices
        : cameraPositionZOnLargerDevices;

      //Renderer
      this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
      this.renderer.setClearColor(0xffffff, 0);
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(modelContainerWidth, modelContainerHeight);

      //Mouse
      this.mouse = {
        x: 0,
        y: 0,
      };

      model.appendChild(this.renderer.domElement);
    }
  };

  onMouseMove = (event: MouseEvent) => {
    if (this.mouse) {
      this.mouse.x = event.clientX - this.windowHalf.x;
      this.mouse.y = event.clientY - this.windowHalf.y;
    }
  };

  addCustomSceneObjects = () => {
    const fileSrc = get(this, 'props.src');
    const loader = new GLTFLoader();

    loader.load(fileSrc, (gltf: any) => {
      this.object = gltf.scene;
      this.object.rotation.x = -0.05;
      this.object.position.y = -0.1;
      this.object.rotation.y = 3.15;
      this.scene.add(this.object);
    });

    const lights = [
      new THREE.DirectionalLight(0xffffff, 0.02),
      new THREE.PointLight(0xffffff, 1, 0),
      new THREE.PointLight(0xffffff, 1, 0),
      new THREE.PointLight(0xffffff, 1, 0),
      new THREE.AmbientLight(0x404040, 2),
    ];

    lights[0].position.set(100, 100, 100);
    lights[0].castShadow = true;
    lights[1].position.set(0, 200, 0);
    lights[2].position.set(100, 200, 100);
    lights[3].position.set(-100, -200, -100);

    this.scene.add(lights[0]);
    this.scene.add(lights[1]);
    this.scene.add(lights[2]);
    this.scene.add(lights[3]);
    this.scene.add(lights[4]);
  };

  startAnimation = () => {
    if (this.mouse) {
      this.camera.position.x += (this.mouse.x - this.camera.position.x * 150) * 0.01;
      this.camera.position.y += (-this.mouse.y - this.camera.position.y * 150) * 0.01;
      this.camera.lookAt(this.scene.position);
    }

    this.renderer.render(this.scene, this.camera);
    this.requestID = window.requestAnimationFrame(this.startAnimation);
  };

  debounceOnMouseMove = debounce(this.onMouseMove, 300);
  debounceHandleResize = debounce(this.handleResize, 300);

  render() {
    return (
      <div
        className="Model3D absolute col-12 flex items-center justify-center"
        ref={this.modelContainerRef}
      >
        <div className="Model3D__model" ref={this.modelRef} />
      </div>
    );
  }
}

export default withBreakpoints<Props>(Model3D);
