import React from 'react';

import { Img } from 'components/base';
import { ArticleImageBlock as IArticleImageBlock } from 'types';

interface Props {
  content: IArticleImageBlock | null;
}

const ArticleImageBlock: React.FC<Props> = ({ content }) => {
  if (!content) {
    return null;
  }

  return (
    <div className="ArticleImageBlock flex flex-col items-start xxl:flex-row py2_5 xxl:py5">
      <Img className="w100" src={content.src} alt={content.alt} />
      {content.caption && (
        <div className="ArticleImageBlock__caption text-caption apercu color-black pt_5 xxl:pt0 xxl:pl1">
          {content.caption}
        </div>
      )}
    </div>
  );
};

export default ArticleImageBlock;
