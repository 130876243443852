import React from 'react';
import cx from 'classnames';
import hashify from 'object-hash';
import get from 'lodash/get';
import withBreakpoints, {
  Breakpoints,
  InjectedProps as WithBreakpointsProps,
} from 'lib/withBreakpoints';
import { DefaultImage } from '../constants/Default';
import { defaultImages } from 'constants/Default';
import sanityImgUtil from 'utils/sanityImgUtil';
import generatePlaceholderArticles from 'utils/generatePlaceholderArticles';
import generateArticleUrl from 'utils/generateArticleUrl';
import Language from 'constants/Language';

import { ImgWithHover, Button } from 'components/base';
import { BlockGallery, ArticleLink } from 'types';

interface PassedProps {
  block?: BlockGallery;
  articleLinks?: ArticleLink[];
  className?: string;
  hasSearchStyling?: boolean;
  isTopicsPage?: boolean;
  isArticleOrSearchPage?: boolean;
}

type Props = PassedProps & WithBreakpointsProps;

const GalleryBlock: React.FC<Props> = ({
  className,
  block,
  articleLinks,
  hasSearchStyling,
  currentBreakpoint,
  isTopicsPage,
  isArticleOrSearchPage,
}) => {
  const defaultImage = defaultImages[1];
  const articlesData = get(block, 'articles') || articleLinks;
  const articles = articlesData ? generatePlaceholderArticles(articlesData) : null;
  const breakpointIsMdDown = [
    Breakpoints.MEDIUM.label,
    Breakpoints.SMALL.label,
    Breakpoints.SMALL_EXTRA_SMALL.label,
    Breakpoints.EXTRA_SMALL.label,
  ].includes(currentBreakpoint);
  const linksPerRowLimit = 3;
  const shouldShowPlaceholderBlocks =
    (!breakpointIsMdDown && !isTopicsPage) ||
    isTopicsPage ||
    (breakpointIsMdDown && isArticleOrSearchPage);

  if (!block && !articles) {
    return null;
  }

  return (
    <div
      className={cx('GalleryBlock flex flex-col border-right-black', className, {
        'border-right-black border-bottom-black': !hasSearchStyling,
      })}
    >
      {block && block.header && (
        <div className="GalleryBlock__header border-left-black block-title-container-padding apercu text-lg color-black bg-color-white">
          {block.header}
        </div>
      )}
      <div className="GalleryBlock__block-container flex flex-row md:flex-wrap">
        {articles &&
          articles.map((article: ArticleLink, index: number) =>
            !!article.title ? (
              <Button
                key={hashify(article)}
                to={generateArticleUrl(article.slug)}
                className="GalleryBlock__block text-decoration-none h100 grid-black-3-4-columns"
                containerClassName="GalleryBlock__outer-block-container md:col-4"
                ariaLabel={Language.t('GalleryBlock.readButtonAriaLabel', {
                  articleTitle: article.title,
                })}
              >
                <div className="GalleryBlock__image-container grid-black-3-4-columns z-3 flex p2 xl:p3 flex items-center justify-center">
                  <div className="w100 flex items-center justify-center">
                    <ImgWithHover
                      className="GalleryBlock__image"
                      src={
                        (article.thumbnailImage && sanityImgUtil(article.thumbnailImage)) ||
                        (article.heroImage && sanityImgUtil(article.heroImage)) ||
                        defaultImage.src
                      }
                      alt={get(article, 'heroImage.alt') || DefaultImage.alt}
                    />
                  </div>
                </div>
                <div className="GalleryBlock__title-container hyphens flex flex-col bg-color-white py_4 px_625 border-left-black">
                  {(article.briefTitle || article.title) && (
                    <div className="flex flex-col">
                      <span className="text-sm apercu color-black">
                        {article.briefTitle || article.title}
                      </span>
                      <span className="link--style-hover-black text-sm apercu color-bronze">
                        {Language.t('GalleryBlock.read')}
                      </span>
                    </div>
                  )}
                </div>
              </Button>
            ) : (
              shouldShowPlaceholderBlocks && (
                <div key={`placeholder-block-${index}`} className="md:col-4">
                  <div className="GalleryBlock__block text-decoration-none h100 grid-black-3-4-columns">
                    <div
                      className={cx(
                        'GalleryBlock__image-container z-3 flex p2 xl:p3 flex items-center justify-center',
                        {
                          'border-top-black': index < linksPerRowLimit,
                        }
                      )}
                    ></div>
                    <div
                      className={cx(
                        'GalleryBlock__title-container hyphens flex flex-col bg-color-white p_4',
                        {
                          'border-left-black': index < articles.length,
                        }
                      )}
                    ></div>
                  </div>
                </div>
              )
            )
          )}
      </div>
    </div>
  );
};

export default withBreakpoints<Props>(GalleryBlock);
