import React from 'react';
import hashify from 'object-hash';

import { Button } from 'components/base';
import { ColumnList, ListItem, TwoColumnTextBlock as ITwoColumnTextBlock } from 'types';

interface Props {
  className?: string;
  block: ITwoColumnTextBlock | null;
}

const TwoColumnTextBlock: React.FC<Props> = ({ block }) => {

  if (!block) {
    return null;
  };

  return (
    <div className="TwoColumnTextBlock apercu md:pb3 flex flex-col">
      <div className="text-article-xs color-green col-12 md:col-6 pb2_5">
        {block.title}
      </div>
      <div className="flex flex-col md:flex-row md:flex-wrap text-article-xs">
        {block.columns.map((column: ColumnList) => (
          <div className="col-12 md:col-6 pb3" key={hashify(column)}>
            <div className="color-green pb1 md:pb2_5">{column.title}</div>
            {column.items.map((item: ListItem) => (
              <div key={hashify(item)} className="mr2">
                <span className="mr_75">{item.text}</span>
                {item.link && item.link.linkLabel && item.link.url && (
                  <Button
                    to={item.link.url}
                    className="inline text-decoration-none color-bronze"
                    ariaLabel={item.link.linkLabel}
                    label={item.link.linkLabel}
                  />
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TwoColumnTextBlock;
