import memoize from 'lodash/memoize';

import get from 'lodash/get';

import { ListItem } from 'types';

export default memoize((data: unknown): ListItem | null => {
  const id = get(data, '_key');
  
  if (!id) {
    return null;
  }

  return {
    id,
    title: get(data, 'title', ''),
    text: get(data, 'text', ''),
    link: {
      url: get(data, 'link.url', ''),
      linkLabel: get(data, 'link.linkLabel', ''),
    },
  };
});
