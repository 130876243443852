import memoize from 'lodash/memoize';

import get from 'lodash/get';

import { ArticleScheduleBlockItem } from 'types';

export default memoize((data: unknown): ArticleScheduleBlockItem | null => {
  const id = get(data, '_key');
  
  if (!id) {
    return null;
  }

  return {
    id,
    time: get(data, 'time', ''),
    event: get(data, 'event', ''),
  };
});
