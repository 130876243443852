import React, { Component } from 'react';
import get from 'lodash/get';
import Flickity from 'flickity';
import cx from 'classnames';
import debounce from 'lodash/debounce';
import sanityImgUtil from 'utils/sanityImgUtil';

import { Img } from 'components/base';
import withBreakpoints, { InjectedProps as WithBreakpointsProps } from 'lib/withBreakpoints';

import { ArticleImageCarouselBlock, ArticleImageCarouselBlockSlide } from 'types';

interface PassProps {
  block: ArticleImageCarouselBlock | null;
}

type Props = PassProps & WithBreakpointsProps;

interface State {
  slides: ArticleImageCarouselBlockSlide[] | null;
  selectedIndex: number;
  loadedImg: boolean[];
}

class ImageCarousel extends Component<Props, State> {
  wrapper: Element | null = null;
  flickity: Flickity | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      slides: props.block && props.block.slides ? props.block.slides : null,
      selectedIndex: 0,
      loadedImg: [],
    };
  }

  handleResize = () => {
    if (this.flickity) {
      this.flickity.destroy();
    }

    this.initializeCarousel();
  };

  debounceHandleResize = debounce(this.handleResize, 300);

  componentDidMount() {
    window.addEventListener('resize', this.debounceHandleResize);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { block, currentBreakpoint } = this.props;
    const { loadedImg } = this.state;

    if (prevProps.currentBreakpoint !== currentBreakpoint) {
      this.handleResize();
    }

    if (
      block &&
      block.slides &&
      block.slides.length <= loadedImg.length &&
      prevState.loadedImg.length !== loadedImg.length
    ) {
      this.handleResize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debounceHandleResize);
  }

  initializeCarousel = () => {
    if (this.wrapper) {
      this.flickity = new Flickity(this.wrapper, {
        initialIndex: 0,
        wrapAround: true,
        draggable: true,
        freeScroll: false,
        prevNextButtons: true,
        accessibility: true,
        pageDots: false,
        on: {
          ready: () => {
            setTimeout(() => this.flickity && this.flickity.resize(), 0);
          },
          select: () => {
            if (this.flickity) {
              this.setState({ selectedIndex: this.flickity.selectedIndex });
            }
          },
        },
      });
    }
  };

  handleImgLoad = () => {
    this.setState((state) => ({ loadedImg: state.loadedImg.concat([true]) }));
  };

  render() {
    const { slides, selectedIndex } = this.state;
    const slidesLength = get(this, 'state.slides', []).length;

    return (
      <div className="ImageCarousel py4">
        <div
          className="ImageCarousel__outer-container 
        py6 md:py4 bg-color-black grid-white flex flex-col items-center justify-center"
        >
          <div
            className={cx('ImageCarousel__container w100', {
              'ImageCarousel__container--multi-image': slidesLength && slidesLength > 1,
            })}
            ref={(node) => (this.wrapper = node)}
          >
            {slides &&
              slides.map((slide: ArticleImageCarouselBlockSlide) => (
                <Img
                  key={slide.image.src}
                  className="ImageCarousel__img px2 xl:px4"
                  src={sanityImgUtil(slide.image, 800)}
                  alt={slide.image.alt}
                  onImgLoad={this.handleImgLoad}
                />
              ))}
          </div>
        </div>
        <div className="ImageCarousel__caption-container border-top-white col-12 flex flex-row justify-between bg-color-black py_4 px_625 apercu text-sm">
          <div className="col-10 color-white flex justify-start">
            {slides && slides[selectedIndex].slideText}
          </div>
          <div className="col-2 color-white flex justify-end">
            {selectedIndex + 1}/{slides && slides.length}
          </div>
        </div>
      </div>
    );
  }
}

export default withBreakpoints<Props>(ImageCarousel);
