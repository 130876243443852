import { parseISO, format } from 'date-fns';

//formatOfDate = date-fns' accepted tokens for dates (M, Mo, MM, MMM, etc.)

export default (unformattedDate: string, formatOfDate: string): string => {
  if (
    !unformattedDate ||
    typeof unformattedDate !== 'string' ||
    !formatOfDate ||
    typeof formatOfDate !== 'string'
  ) {
    console.error(
      `formatISOStringToDate: parameters not entered correctly on ${window.location.href}`
    );
    return '';
  }

  const parsedDate = parseISO(unformattedDate);

  return format(parsedDate, formatOfDate);
};
