import memoize from 'lodash/memoize';

import get from 'lodash/get';

import { ArticleImageBlock } from 'types';

export default memoize((data: unknown): ArticleImageBlock | null => {
  const id = get(data, '_key');

  if (!id) {
    return null;
  }

  return {
    id,
    type: get(data, '_type', 'articleImageBlock'),
    src: get(data, 'src', ''),
    caption: get(data, 'caption', ''),
    alt: get(data, 'alt', ''),
  };
});
