import memoize from 'lodash/memoize';

import get from 'lodash/get';

import { ArticleEventBlock } from 'types';

export default memoize((data: unknown): ArticleEventBlock | null => {
  const id = get(data, '_key');

  if (!id) {
    return null;
  }

  return {
    id,
    type: get(data, '_type', 'articleEventBlock'),
    header: get(data, 'header', ''),
    location: get(data, 'location'),
    startDate: get(data, 'startDate', ''),
    endDate: get(data, 'endDate', ''),
    description: get(data, 'description', []),
  };
});
