import React from 'react';
import { Helmet } from 'react-helmet';
import { SeoSettings } from 'types';

const DEFAULT_TITLE = 'MY DEFAULT TITLE';

// const DEFAULT_TITLE = 'Pratt Institute | School of Architecture';
const DEFAULT_DESCRIPTION =
  'The School of Architecture educates future leaders in design, planning, and management disciplines by offering undergraduate and graduate degrees in the professional fields of architecture, urban design, city and regional planning, construction and facilities management, environmental management, urban placemaking, historic preservation, and real estate.';
const DEFAULT_IMAGE = '/assets/images/share-card.png';

interface Props {
  title?: string;
  description?: string;
  image?: string;
}

const Meta: React.FC<Props> = ({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  image = DEFAULT_IMAGE,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:site_name" content={title} />
      <meta property="og:locale" content="en_US" />

      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
      <meta name="twitter:site" content="@prattsoa" />
      <meta name="twitter:creator" content="@prattsoa" />
      <meta property="twitter:card" content="summary_large_image" />
    </Helmet>
  );
};

const GenericPage: React.FC<{
  title: string;
  seo: SeoSettings;
}> = ({ title, seo }) => {
  return (
    <Meta
      title={seo.title || title || DEFAULT_TITLE}
      description={seo.description || title || DEFAULT_DESCRIPTION}
      image={seo.image.src || DEFAULT_IMAGE}
    />
  );
};

const Article: React.FC<{
  title: string;
  heroImage: string;
  seo: SeoSettings;
}> = ({ title, heroImage, seo }) => {
  return (
    <Meta
      title={seo.title || title || DEFAULT_TITLE}
      description={seo.description || title || DEFAULT_DESCRIPTION}
      image={seo.image.src || heroImage || DEFAULT_IMAGE}
    />
  );
};

const Search: React.FC<{
  title: string;
}> = ({ title }) => {
  return (
    <Meta
      title={title || DEFAULT_TITLE}
      description={title || DEFAULT_DESCRIPTION}
      image={DEFAULT_IMAGE}
    />
  );
};

export { GenericPage, Article, Search };

export default Meta;
