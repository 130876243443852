import memoize from 'lodash/memoize';

import get from 'lodash/get';

import { ArticleImageCarouselBlock } from 'types';

export default memoize((data: unknown): ArticleImageCarouselBlock | null => {
  const id = get(data, '_key');

  if (!id) {
    return null;
  }

  return {
    id,
    type: get(data, '_type', 'articleImageCarouselBlock'),
    slides: get(data, 'slides', [])
  };
});
